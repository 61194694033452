import React, { useState, useEffect } from 'react';
import { Button,Grid,  Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Checkbox, TextField } from '@mui/material';
import { Link } from 'react-router-dom'; 
import { useForm } from "react-hook-form";
import {apiDocument} from '../../services/api/documents/Document';
import { apiProvider } from '../../services/api/utilities/Provider';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {authVerify} from "../../services/api/utilities/AuthVerify";
import IconButton from '@mui/material/IconButton';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useTranslation } from "react-i18next";

const IndexComponent = (props) => {
    const { register } = useForm();
    const [items, setItems] = useState([]);
    const [page, setPage] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [role, setRole] = useState('');
    const [sortIndex, setSortIndex] = useState(-1);
    const [sortName, setSortName] = useState('');
    const [hover, setHover] = useState(-1);
    const [sortOrder, setSortOrder] = useState('asc');
    const [length, setLength] = useState(0);
    const [queryParams, setQueryParams] = useState({ start: 0, end: 10, search: '' });
    const { t, i18n, ready } = useTranslation();

    useEffect(() => {
        authVerify.verifyUser();
        async function fetchData() {
            props.setIsOpenPay(false);
            await refreshItems();
        }
        setRole(localStorage.getItem('role'));
        fetchData();
    }, []);

    const clickAll = (e)=>{
        for(let i=0;i<items.length;i++)
        {
            items[i].isChecked=e.target.checked;   
        }
        setItems([...items]);
    }

    const clickStar = (row)=>{
        row.isStar = !row.isStar;
        setItems([...items]);
        apiDocument.clickStar(row.id);
    }

    const sort = async (name)=>{
        setPage(0);
        queryParams.order = name;
        setQueryParams({ start: 0, end: 10, search: queryParams.search, order: sortName });
        await refreshItems(queryParams);
    }

    const setSort = async (index) => {
        let tempSortName = '';
        if(index===sortIndex && sortOrder!=='desc')
        {
            setSortOrder('desc');
            if(index===0)
            {
                tempSortName = '-sendDate';
            }
            if(index===1)
            {
                tempSortName ='-name';
            }
            if(index===2)
            {
                tempSortName ='-sendUserProfileName';
            }
        }
        else
        {
            setSortOrder('asc');
            if(index===0)
            {
                tempSortName = 'sendDate';
            }
            if(index===1)
            {
                tempSortName ='name';
            }
            if(index===2)
            {
                tempSortName ='sendUserProfileName';
            }
        }
        setSortName(tempSortName);
        setSortIndex(index);
        let query = { start: 0, end: 10, search: queryParams.query, order: tempSortName };
        setQueryParams(query);
        await refreshItems(query);
    }

    const refreshItems = async (tempQuery=null)=>{
        let data = null;
        setIsLoading(true);
        if(tempQuery!==null)
            data = await apiDocument.search(tempQuery);
        else
            data = await apiDocument.search(queryParams);
        
        if(data.code==='ERR_NETWORK')
        {
            localStorage.setItem('token', '');
            localStorage.setItem('fullName', '');
            localStorage.setItem('role', '');
            localStorage.setItem('email', '');
            localStorage.setItem('avatar', '');
            window.location.href = "/";
        }

        if (data != null) {
            for(var i=0;i<data.items.length;i++)
            {
                data.items[i].isChecked=false;
            }
            setItems(data.items);
            setLength(data.length);
            setIsLoading(false);
        }
    }
    
    const search = async(query) => {        
        setPage(0);
        queryParams.search = query;
        setQueryParams({ start: 0, end: 10, search: query, order: sortName });
        await refreshItems(queryParams);
    }

    const handleChangePage = async (event, newPage) => {
        setPage(newPage);
        let query = {start:(newPage*10), end: ((newPage+1)*10), search:'', order: sortName };
        setQueryParams(query);
        await refreshItems(query);
    };

    const pageLeft = async () => {
        let newPage=0;
        if(page>1)
            newPage=page-1;
        setPage(newPage);
        let query = {start:(newPage*10), end: ((newPage+1)*10), search:'', order: sortName };
        setQueryParams(query);
        await refreshItems(query);
    };

    const pageRight = async () => {
        let newPage = 1;
        if(page<=parseInt(length/10))
            newPage=page+1;
        setPage(newPage);
        let query = {start:(newPage*10), end: ((newPage+1)*10), search:'', order: sortName };
        setQueryParams(query);
        await refreshItems(query);
    };

    const downloadFile = async (id) => {
        apiDocument.openUrl(`/api/document/file/${id}`);
    };

    const downloadFiles = async () => {
        for(let i=0;i<items.length;i++)
        {
            if(items[i].isChecked)
            {
                await downloadFile(items[i].id);
            }
        }
    };

    const clickDocument = async(id) => {
        window.location.href = "/sign?id="+id;
    }

    return (
        <div className="main-body">
            {(role==='2' || role==='3') &&
                <Grid container>
                    <Grid item xs={6} className='only-mobile'>
                        <Link className="first-tab" to="/create">{t("index_text1")}</Link>
                    </Grid>
                    <Grid item xs={6} className='only-mobile'>
                        <Link className="second-tab">{t("index_text2")}</Link>
                    </Grid>
                </Grid>
            }
            {(role!=='2' && role!=='3') &&
                <Grid container>
                    <Grid item xs={12} className='only-mobile'>
                        <Link to="/index" className="first-tab">{t("index_text3")}</Link>
                    </Grid>
                </Grid>
            }
            <div className="not-mobile">
                {isLoading
                && <Grid container className="padding-body">
                        <div className="loader-blue"></div>
                    </Grid>
                }
                {!isLoading && 
                <div>
                    <Grid container>
                        <Grid item xs>
                            
                        </Grid>
                        <Grid item style={{width: "300px"}} className="right-element search-field">
                            <i className="search"></i>
                            <TextField autoComplete='off' placeholder={t("index_text4")} className='search-input' {...register("search",{onChange: (e) => search(e.target.value)})}/>
                        </Grid>
                    </Grid>
                    <Grid container className="padding-body">
                        <Grid item xs={12}>
                            <TableContainer className="document-list">
                                <Table aria-labelledby="tableTitle" aria-label="enhanced table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell padding="checkbox">
                                                <Checkbox onClick={(e)=>clickAll(e)} color="primary"/>
                                            </TableCell>
                                            <TableCell>
                                                <i className='arrow-list'></i>
                                            </TableCell>
                                            <TableCell>
                                                {queryParams.order==='star' && 
                                                    <i className='star-row-click' onClick={()=>sort('')}></i>
                                                }
                                                {queryParams.order!=='star' && 
                                                    <i className='star' onClick={()=>sort('star')}></i>
                                                }
                                            </TableCell>
                                            <TableCell align="left" className='sortColumn' onClick={()=>setSort(0)} onMouseOver={()=>setHover(0)} onMouseOut={()=>setHover(-1)}><span>{t("index_text5")}</span>{sortIndex===0 && sortOrder==='asc' && <span><ExpandMoreIcon/></span>}{sortIndex===0 && sortOrder==='desc' && <span><ExpandLessIcon/></span>}{hover===0 && sortIndex!==0 && <span><ExpandMoreIcon className="gray-icon"/></span>}</TableCell>
                                            <TableCell align="left" className='sortColumn' onClick={()=>setSort(1)} onMouseOver={()=>setHover(1)} onMouseOut={()=>setHover(-1)}><span>{t("index_text6")}</span>{sortIndex===1 && sortOrder==='asc' && <span><ExpandMoreIcon/></span>}{sortIndex===1 && sortOrder==='desc' && <span><ExpandLessIcon/></span>}{hover===1 && sortIndex!==1 && <span><ExpandMoreIcon className="gray-icon"/></span>}</TableCell>
                                            <TableCell align="left" className='sortColumn' onClick={()=>setSort(2)} onMouseOver={()=>setHover(2)} onMouseOut={()=>setHover(-1)}><span>{t("index_text7")}</span>{sortIndex===2 && sortOrder==='asc' && <span><ExpandMoreIcon/></span>}{sortIndex===2 && sortOrder==='desc' && <span><ExpandLessIcon/></span>}{hover===2 && sortIndex!==2 && <span><ExpandMoreIcon className="gray-icon"/></span>}</TableCell>
                                            <TableCell align="left" className='sortColumn' onClick={()=>setSort(3)} onMouseOver={()=>setHover(3)} onMouseOut={()=>setHover(-1)}><span>{t("index_text8")}</span>{sortIndex===3 && sortOrder==='asc' && <span><ExpandMoreIcon/></span>}{sortIndex===3 && sortOrder==='desc' && <span><ExpandLessIcon/></span>}{hover===3 && sortIndex!==3 && <span><ExpandMoreIcon className="gray-icon"/></span>}</TableCell>
                                            <TableCell align="left" className='sortColumn'><span>{t("index_text9")}</span></TableCell>
                                            <TableCell align="left" className='sortColumn'><span>{t("index_text9b")}</span></TableCell>
                                            <TableCell align="left">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<i className='edit'></i>&nbsp;<i className='document'></i></TableCell>
                                            <TableCell align="left"><Button className="button button-small-header" onClick={()=>downloadFiles()}><i className='download'>&nbsp;</i></Button></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                    {items.map((row, index) => {
                                        return(
                                        <TableRow key={index}>
                                                <TableCell padding="checkbox">
                                                    <Checkbox color="primary" checked={row.isChecked} onClick={(e)=>{row.isChecked = e.target.checked; setItems([...items]);}}/>
                                                </TableCell>
                                                <TableCell>
                                                    {row.documentType===1 && <i className='arrow-up'></i>}
                                                    {row.documentType===2 && <i className='arrow-down'></i>}
                                                </TableCell>
                                                <TableCell>
                                                    {row.isStar && 
                                                        <i className='star-row-click' onClick={()=>clickStar(row)}></i>
                                                    }
                                                    {!row.isStar && 
                                                        <i className='star-row' onClick={()=>clickStar(row)}></i>
                                                    }
                                                </TableCell>
                                                <TableCell align="left"><a>
                                                    <span>{row.sendDateName}</span></a>
                                                </TableCell>
                                                <TableCell align="left">{row.name}</TableCell>
                                                <TableCell align="left">{row.sendUserProfileName}</TableCell>
                                                <TableCell align="left">{row.items}</TableCell>
                                                <TableCell align="left">{row.itemsSigned}&nbsp;&nbsp;&nbsp;
                                                    {(row.status===0 || row.status===1) && <i className="red-dot"></i>}
                                                    {row.status===2 && <i className="yellow-dot"></i>}
                                                    {row.status===3 && <i className="green-dot"></i>}
                                                </TableCell>
                                                <TableCell align="left">{row.randomCode}</TableCell>
                                                <TableCell align="left">
                                                    {(row.isToSigned>0 && !row.isMySigned) && <Link className="button" color="primary" to={"/sign?id="+row.id}>{t("index_text10")}</Link>}
                                                    {(row.isToSigned===0 || row.isMySigned) && <Link className="button button-white" to={"/sign?id="+row.id}>{t("index_text11")}</Link>}
                                                </TableCell>
                                                <TableCell align="left"><Button className="button button-small" onClick={()=>downloadFile(row.id)}><i className='download-white'>&nbsp;</i></Button></TableCell>
                                            </TableRow>
                                        );
                                    })}
                                    </TableBody>
                                </Table>
                                <TablePagination component="div" count={length} rowsPerPage={10} page={page} labelRowsPerPage="" onPageChange={handleChangePage}/>
                            </TableContainer>
                        </Grid>
                    </Grid>
                </div>
                }
            </div>
            {isLoading && 
                <Grid container className="padding-body only-mobile">
                    <div className="loader-blue"></div>
                </Grid>
            }
            {!isLoading && 
            <div className="wrapper only-mobile">
                <Grid container>
                    <Grid item className="search-field" xs={12}>
                        <i className="search"></i>
                        <TextField autoComplete='off' placeholder={t("index_text12")} className='search-input' {...register("search2",{onChange: (e) => search(e.target.value)})}/>
                    </Grid>
                </Grid>

                <Grid container>
                    {items.map((row, index) => {
                        return(
                            <Grid item className={"document-box"+" "+((!row.isToSigned || row.isMySigned) ? "no-background":"")} xs={12} key={index} onClick={()=>clickDocument(row.id)}>
                                <Grid container>
                                    <Grid item xs={1}>
                                        <Grid container>
                                            <Grid item>
                                                {row.documentType===1 && <i className='arrow-up'></i>}
                                                {row.documentType!==1 && <i className='arrow-down'></i>}
                                            </Grid>
                                            <Grid item>
                                                {row.isToSigned!==0 && !row.isMySigned && 
                                                    <i className='edit'></i>
                                                }
                                                {(row.isToSigned===0 || row.isMySigned) && 
                                                    <i className='document'></i>
                                                }
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <p>{(row.status===0 || row.status===1) && <i className="red-dot"></i>}
                                            {row.status===2 && <i className="yellow-dot"></i>}
                                            {row.status===3 && <i className="green-dot"></i>}&nbsp;&nbsp;{row.name}</p>
                                        <p><span>{t("index_text13")}: </span>{row.sendDateName}&nbsp; 
                                            {row.isFull && <i className='clock-row'></i>}
                                            {row.isSemi && !row.isFull && <i className='semi-clock-row'></i>}
                                            {row.isQuarter && !row.isFull && !row.isSemi && <i className='quarter-clock-row'></i>}
                                            {row.isEmpty && !row.isQuarter && !row.isFull && !row.isSemi && <i className='empty-clock-row'></i>}
                                            {!row.isEmpty && !row.isQuarter && !row.isFull && !row.isSemi && <i className='empty-clock-row'></i>}</p>
                                        <p><span>{t("index_text14")}: </span>{row.sendUserProfileName}</p>
                                    </Grid>
                                    <Grid item xs={2}>
                                    </Grid>
                                    <Grid item xs={1}>
                                        <IconButton
                                            size="large"
                                            edge="start"
                                            color="inherit"
                                            aria-label="menu"
                                            sx={{ mr: 2, mt:0.5 }}>
                                            <ArrowForwardIosIcon/>
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </Grid>
                        );
                    })}
                </Grid>
                <Grid container>
                    <Grid item xs={10}>
                    </Grid>
                    <Grid item xs={1} onClick={()=>pageLeft()}>
                        <IconButton
                            size="large"
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            sx={{ mr: 2, mt:0.5 }}>
                            <ArrowBackIosIcon/>
                        </IconButton>
                    </Grid>
                    <Grid item xs={1} onClick={()=>pageRight()}>
                        <IconButton
                            size="large"
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            sx={{ mr: 2, mt:0.5 }}>
                            <ArrowForwardIosIcon/>
                        </IconButton>
                    </Grid>
                </Grid>
            </div>
            }
        </div>
    );
}

export default IndexComponent;