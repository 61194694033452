import React, { useState, useEffect } from 'react';
import { Button,Grid,  Table, TableBody, TableCell, TableContainer, TableHead, TableRow,TextField,Box} from '@mui/material';
import { useForm } from "react-hook-form";
import {apiDocument} from '../../services/api/documents/Document';
import ContactListComponent from './ContactListComponent';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { useTranslation } from "react-i18next";

const ContactComponent = (props) => {
    const [items, setItems] = useState([]);
    const [groups, setGroups] = useState([]);
    const [groupsContacts, setGroupsContacts] = useState([]);
    const [validationMessage, setValidationMessage] = useState('');
    const [validationGroupMessage, setValidationGroupMessage] = useState('');
    const [groupName, setGroupName] = useState('');
    const [groupId, setGroupId] = useState(0);
    const { t, i18n, ready } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
    const [openGroupDelete, setOpenGroupDelete] = useState(false);
    const [openGroupContactDelete, setOpenGroupContactDelete] = useState(false);
    const [id, setIdValue] = useState(-1);
    const [idGroup, setIdGroupValue] = useState(-1);
    const [isEditMode, setEditMode] = useState(false);
    const [isEditModeForGroup, setEditModeForGroup] = useState(false);
    const [isEditGroupMode, setEditGroupMode] = useState(false);
    const [isOpenContactList, setOpenContactList] = useState(false);
    const { register,  getValues, setValue } = useForm();

    useEffect(() => {
        async function fetchData() {
            let contacts = await apiDocument.contacts();
            if(contacts!==null)
            {
                setItems(contacts.items);
            }

            let groups = await apiDocument.groups();
            if(groups!==null)
            {
                setGroups(groups.items);
            }
        }
        fetchData();
    }, []);

    const refreshItems = async (name) => {
        let contacts = await apiDocument.contacts();
        if(contacts!==null)
        {
            setItems(contacts.items);
        }

        let groups = await apiDocument.groups();
        if(groups!==null)
        {
            if(name)
            {
                if(groups.items.filter(d=>d.name==name).length>0)
                    groups.items.filter(d=>d.name==name)[0].isExpand=true;
            }
            setGroups(groups.items);
        }
    }

    const removeUser = async () => {
        let result = await apiDocument.removeContact({id: id});
        if(result)
        {
            await refreshItems();
            setOpenDelete(false);
        }
    };

    const removeGroup = async () => {
        let result = await apiDocument.removeGroup({id: id});
        if(result)
        {
            await refreshItems();
            setOpenGroupDelete(false);
        }
    };

    const removeGroupContact = async () => {
        let result = await apiDocument.removeGroupContact({id:id});
        if(result)
        {
            await refreshItems();
            setOpenGroupContactDelete(false);
        }
    };

    const showRemove = (id) => {
        setIdValue(id);
        setOpenDelete(true);
    };

    const showGroupRemove = (id) => {
        setIdValue(id);
        setOpenGroupDelete(true);
    };

    const showRemoveGroupContact = (id) => {
        setIdValue(id);
        setOpenGroupContactDelete(true);
    };

    const handleClose = () => {
        props.setIsOpenContacts(false);
        window.location.reload();
    };

    const setContact = (id,email,firstName, lastName, company, city) => {
        setIdValue(id);
        setValue("email",email);
        setValue("firstName",firstName);
        setValue("lastName",lastName);
        setValue("company",company);
        setValue("companyNumber",city);
        setEditMode(true);
    }

    const setGroup = (id,name) => {
        setIdGroupValue(id);
        setValue("name",name);
        setEditGroupMode(true);
    }

    const cancel = () => {
        setValue("email","");
        setValue("firstName","");
        setValue("lastName","");
        setValue("company","");
        setValue("companyNumber","");
        setValue("emailG","");
        setValue("firstNameG","");
        setValue("lastNameG","");
        setValue("companyG","");
        setValue("companyNumberG","");
        setEditMode(false);
        setEditModeForGroup(false);
    }

    const cancelGroup = () => {
        setValue("name","");
        setEditGroupMode(false);
    }

    const add = async () => {
        setEditMode(true);
        setIdValue(0);
        setValue("email","");
        setValue("firstName","");
        setValue("lastName","");
        setValue("company","");
        setValue("companyNumber","");
    }

    const addForGroup = async () => {
        setEditModeForGroup(true);
        setIdValue(0);
        setValue("emailG","");
        setValue("firstNameG","");
        setValue("lastNameG","");
        setValue("companyG","");
        setValue("companyNumberG","");
    }

    const addGroup = async () => {
        setEditGroupMode(true);
        setIdGroupValue(0);
        setValue("name","");
    }

    const addContact = async () => {
        var email = getValues("email");
        var firstName = getValues("firstName");
        var lastName = getValues("lastName");
        var company = getValues("company");
        var companyNumber = getValues("companyNumber");
        if(email===null || email==='')
        {
            setValidationMessage(t("users_text21"));
            return;   
        }
        if(email.indexOf('@')<0)
        {
            setValidationMessage(t("users_text22"));
            return;   
        }
        if(firstName===null || firstName==='')
        {
            setValidationMessage(t("users_text23"));
            return;   
        }
        if(lastName===null || lastName==='')
        {
            setValidationMessage(t("users_text24"));
            return;   
        }
        let contact = await apiDocument.saveContact({email:email, firstName: firstName, lastName: lastName, company: company, roleId: 2, id: id, companyNumber:companyNumber});
        if(contact!=null)
        {
            refreshItems();
            setEditMode(false);
        }
    }

    const addContactForGroup = async (groupId) => {
        var email = getValues("emailG");
        var firstName = getValues("firstNameG");
        var lastName = getValues("lastNameG");
        var company = getValues("companyG");
        var companyNumber = getValues("companyNumberG");
        if(email===null || email==='')
        {
            setValidationMessage(t("users_text21"));
            return;   
        }
        if(email.indexOf('@')<0)
        {
            setValidationMessage(t("users_text22"));
            return;   
        }
        if(firstName===null || firstName==='')
        {
            setValidationMessage(t("users_text23"));
            return;   
        }
        if(lastName===null || lastName==='')
        {
            setValidationMessage(t("users_text24"));
            return;   
        }
        let contact = await apiDocument.saveContact({email:email, firstName: firstName, lastName: lastName, company: company, roleId: 2, id: id, companyNumber:companyNumber, contactGroupId: groupId});
        if(contact!=null)
        {
            refreshItems();
            setEditModeForGroup(false);
        }
    }

    const addContactGroup = async () => {
        var name = getValues("name");
        if(name===null || name==='')
        {
            setValidationGroupMessage('Pole nazwa grupy jest wymagana');
            return;   
        }

        let group = await apiDocument.saveGroup({name:name, id: idGroup});
        if(group!=null)
        {
            refreshItems(name);
            setEditGroupMode(false);
        }
    }

    const setExpand = async (row) => {
        row.isExpand = !row.isExpand;
        setGroups([...groups]);
    }

    return (
        <div>
            <Dialog open={props.isOpenContacts} onClose={handleClose} style={{padding:"10px",maxHeiught:'90%'}} maxWidth="lg" fullWidth>
                <div className='dialog-user'>
                    <DialogTitle>
                        {t("contact_text1")}
                    </DialogTitle>
                    <DialogContent>
                    <DialogContentText>
                        <a className="x dialog-close" onClick={handleClose}></a>
                        <Grid container>
                            <Grid item xs>
                                <h2>Zarządzaj listą osób</h2>
                            </Grid>
                            <Grid item xs={2}>
                                <Button className="button right" onClick={()=>add()}>+ {t("contact_text9")}</Button>
                            </Grid>
                        </Grid>
                        <br/>
                        <br/>
                        {isEditMode && 
                            <Grid container className="add-person-box">
                                <Grid item xs={12}>
                                    <h3>{id<=0 ? t("contact_text9"):t("contact_text8")}</h3>
                                </Grid>
                                {validationMessage && <Grid container>
                                    <div className="validation-error">{validationMessage}</div>
                                </Grid>
                                }
                                <Grid container spacing={1}>
                                    <Grid item xs={6}>
                                        <TextField autoComplete='off' {...register("firstName", { required: true })} placeholder={t("users_text6")}/>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField autoComplete='off' {...register("lastName", { required: true })} placeholder={t("users_text7")}/>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={1}>
                                    <Grid item xs={6}>
                                        <TextField autoComplete='off' autoFocus {...register("email", { required: true })} placeholder={t("users_text4")}/>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField autoComplete='off' {...register("company", { required: true })} placeholder={t("users_text5")}/>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={1}>
                                    <Grid item xs={6}>
                                        <TextField autoComplete='off' {...register("companyNumber", { required: true })} placeholder={t("users_text5c")}/>
                                    </Grid>
                                </Grid>
                                <Grid container xs={12} spacing={1}>
                                    <Grid item xs={6}>
                                        <Button className="button-person" onClick={()=>addContact()} fullWidth>{t("users_text8")}</Button>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Button className="button-person" onClick={()=>cancel()} fullWidth style={{marginLeft:10}}>{t("users_text9")}</Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        }
                        {isEditMode && 
                            <br/>
                        }
                        <Grid>
                             <TableContainer className="user contact-list">
                                <Table aria-labelledby="tableTitle" aria-label="enhanced table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="left">{t("contact_text2")}</TableCell>
                                            <TableCell align="left">{t("contact_text3")}</TableCell>
                                            <TableCell align="left" className="not-mobile-500">{t("contact_text4")}</TableCell>
                                            <TableCell align="left" className="not-mobile-500">{t("contact_text5")}</TableCell>
                                            <TableCell align="left" className="not-mobile">{t("contact_text6")}</TableCell>
                                            <TableCell align="left"></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {items.map((row, index) => {
                                            return (
                                            <TableRow key={index}>
                                                <TableCell align="left">{row.firstName}</TableCell>
                                                <TableCell align="left">{row.lastName}</TableCell>
                                                <TableCell align="left" className="not-mobile-500">{row.email}</TableCell>
                                                <TableCell align="left" className="not-mobile-500">{row.company}</TableCell>
                                                <TableCell align="left" className="not-mobile">{row.companyNumber}</TableCell>
                                                <TableCell align="left"><a className="x" onClick={()=>showRemove(row.id)}></a>&nbsp;&nbsp;&nbsp;&nbsp;<a className='user-list-edit' onClick={()=>setContact(row.id, row.email, row.firstName, row.lastName, row.company, row.companyNumber)}></a></TableCell>
                                            </TableRow>);
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                        <br/>
                        <br/>
                        <Grid container>
                            <Grid item xs>
                                <h2>Zarządzaj listą grup</h2>
                            </Grid>
                            <Grid item xs={2}>
                                <Button className="button right" onClick={()=>addGroup()}>+ Dodaj grupę</Button>
                            </Grid>
                        </Grid>
                        <br/>
                        <br/>
                        {isEditGroupMode && 
                            <Grid container className="add-person-box">
                                <Grid item xs={12}>
                                    <h3>{idGroup<=0 ? 'Dodaj nową grupę':'Edycja grupy'}</h3>
                                </Grid>
                                {validationGroupMessage && <Grid container>
                                    <Grid item>
                                        <div className="validation-error">{validationGroupMessage}</div>
                                        <br/>
                                    </Grid>
                                </Grid>
                                }
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <TextField autoComplete='off' {...register("name", { required: true })} placeholder='Nazwa grupy' inputProps={{ maxLength: 50 }}/>
                                    </Grid>
                                </Grid>
                                <Grid container xs={12} spacing={1}>
                                    <Grid item xs={6}>
                                        <Button className="button-person" onClick={()=>addContactGroup()} fullWidth>{t("users_text8")}</Button>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Button className="button-person" onClick={()=>cancelGroup()} fullWidth style={{marginLeft:10}}>{t("users_text9")}</Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        }
                        {isEditGroupMode && 
                            <br/>
                        }
                        <Grid>
                            <TableContainer className="user user-list template-list">
                                <Table aria-labelledby="tableTitle" aria-label="enhanced table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="left">Nazwa grupy</TableCell>
                                            <TableCell align="left" className="not-mobile">Liczba osób w grupie</TableCell>
                                            <TableCell align="left"></TableCell>
                                            <TableCell align="left"></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {groups.map((row, index) => {
                                            return (
                                                <>
                                                    <TableRow key={index} className={row.isExpand ? 'background-blue':''}>
                                                        <TableCell align="left">{row.name}</TableCell>
                                                        <TableCell align="left" className="not-mobile">{row.length}</TableCell>
                                                        <TableCell align="left"><a className="x" onClick={()=>showGroupRemove(row.id)}></a>&nbsp;&nbsp;&nbsp;&nbsp;<a className='user-list-edit' onClick={()=>setGroup(row.id, row.name)}></a></TableCell>
                                                        <TableCell align="left">{!row.isExpand && <a className="down" onClick={()=>setExpand(row)}></a>}{row.isExpand && <a className="up" onClick={()=>setExpand(row)}></a>}</TableCell>
                                                    </TableRow>
                                                    {row.isExpand && 
                                                    <TableRow>
                                                        <TableCell align="left" colSpan={4} className='no-padding'>
                                                            <Grid container>
                                                                <Grid item className='background-blue' xs={12}>
                                                                    <TableContainer className="document-list user user-list">
                                                                        <Table aria-labelledby="tableTitle" aria-label="enhanced table">
                                                                            <TableHead>
                                                                                <TableRow>
                                                                                    <TableCell align="left">{t("contact_text2")}</TableCell>
                                                                                    <TableCell align="left">{t("contact_text3")}</TableCell>
                                                                                    <TableCell align="left" className="not-mobile">{t("contact_text4")}</TableCell>
                                                                                    <TableCell align="left" className="not-mobile">{t("contact_text5")}</TableCell>
                                                                                    <TableCell align="left" className="not-mobile">{t("contact_text6")}</TableCell>
                                                                                    <TableCell align="left"></TableCell>
                                                                                </TableRow>
                                                                            </TableHead>
                                                                            <TableBody>
                                                                                {row.contacts.map((contact, index) => {
                                                                                    return (
                                                                                    <TableRow key={index}>
                                                                                        <TableCell align="left">{contact.firstName}</TableCell>
                                                                                        <TableCell align="left">{contact.lastName}</TableCell>
                                                                                        <TableCell align="left" className="not-mobile">{contact.email}</TableCell>
                                                                                        <TableCell align="left" className="not-mobile">{contact.company}</TableCell>
                                                                                        <TableCell align="left" className="not-mobile">{contact.companyNumber}</TableCell>
                                                                                        <TableCell align="left"><a className="x" onClick={()=>showRemoveGroupContact(contact.id)}></a></TableCell>
                                                                                    </TableRow>);
                                                                                })}
                                                                            </TableBody>
                                                                        </Table>
                                                                    </TableContainer>
                                                                    <br/>
                                                                    <Grid container className="not-mobile">
                                                                        <Grid xs={7} item xsOffset={1}>
                                                                        </Grid>
                                                                        <Grid xs={2} item xsOffset={1}>
                                                                            <Box px={1}>
                                                                                <a className="button button-white" onClick={()=>{setGroupId(row.id);setOpenContactList(true); setGroupName(row.name);}}>Wybierz osobę z listy</a>
                                                                            </Box>
                                                                        </Grid>
                                                                        <Grid xs={3} item xsOffset={1}>
                                                                            <Box px={1}>
                                                                                <a className="button button-white button-center" onClick={()=>{addForGroup()}}>+ Dodaj kolejną osobę do grupy</a>
                                                                            </Box>
                                                                        </Grid>
                                                                    </Grid>
                                                                    <Grid container className="only-mobile">
                                                                        <Grid xs={6} item xsOffset={1}>
                                                                            <Box px={1}>
                                                                                <a className="button button-white" onClick={()=>{setGroupId(row.id);setOpenContactList(true); setGroupName(row.name);}}>Wybierz osobę z listy</a>
                                                                            </Box>
                                                                        </Grid>
                                                                        <Grid xs={6} item xsOffset={1}>
                                                                            <Box px={1}>
                                                                                <a className="button button-white button-center" onClick={()=>{addForGroup()}}>+ Dodaj kolejną osobę do grupy</a>
                                                                            </Box>
                                                                        </Grid>
                                                                    </Grid>
                                                                    <br/>                                            
                                                                    {isEditModeForGroup && 
                                                                        <Grid container className="add-person-box">
                                                                            <Grid item xs={12}>
                                                                                <h3>{id<=0 ? t("contact_text9"):t("contact_text8")}</h3>
                                                                            </Grid>
                                                                            {validationMessage && <Grid container>
                                                                                <div className="validation-error">{validationMessage}</div>
                                                                            </Grid>
                                                                            }
                                                                            <Grid container spacing={1}>
                                                                                <Grid item xs={6}>
                                                                                    <TextField autoComplete='off' {...register("firstNameG", { required: true })} placeholder={t("users_text6")}/>
                                                                                </Grid>
                                                                                <Grid item xs={6}>
                                                                                    <TextField autoComplete='off' {...register("lastNameG", { required: true })} placeholder={t("users_text7")}/>
                                                                                </Grid>
                                                                            </Grid>
                                                                            <Grid container spacing={1}>
                                                                                <Grid item xs={6}>
                                                                                    <TextField autoComplete='off' autoFocus {...register("emailG", { required: true })} placeholder={t("users_text4")}/>
                                                                                </Grid>
                                                                                <Grid item xs={6}>
                                                                                    <TextField autoComplete='off' {...register("companyG", { required: true })} placeholder={t("users_text5")}/>
                                                                                </Grid>
                                                                            </Grid>
                                                                            <Grid container spacing={1}>
                                                                                <Grid item xs={6}>
                                                                                    <TextField autoComplete='off' {...register("companyNumberG", { required: true })} placeholder={t("users_text5c")}/>
                                                                                </Grid>
                                                                            </Grid>
                                                                            <Grid container xs={12} spacing={1}>
                                                                                <Grid item xs={6}>
                                                                                    <Button className="button-person" onClick={()=>addContactForGroup(row.id)} fullWidth>{t("users_text8")}</Button>
                                                                                </Grid>
                                                                                <Grid item xs={6}>
                                                                                    <Button className="button-person" onClick={()=>cancel()} fullWidth style={{marginLeft:10}}>{t("users_text9")}</Button>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Grid>
                                                                    }
                                                                    {isEditModeForGroup && 
                                                                        <br/>
                                                                    }
                                                                </Grid>
                                                            </Grid>
                                                        </TableCell>
                                                    </TableRow>}
                                                </>);
                                            })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </DialogContentText>
                    </DialogContent>
                    <DialogActions >
                        <Grid container spacing={2} className="right-side action-margin">
                            <a className="button button-cancel" fullWidth onClick={()=>handleClose()}>{t("contact_text7")}</a>&nbsp;&nbsp;
                        </Grid>
                    </DialogActions>
                </div>
            </Dialog>
            <Dialog open={openDelete} onClose={()=>setOpenDelete(false)} style={{padding:"10px"}}>
                <DialogContent>
                    <DialogContentText>
                        Czy na pewno chcesz usunąć kontakt?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Grid container spacing={2}>
                        <Grid item xs={4}>
                            <a onClick={()=>setOpenDelete(false)} className="button button-cancel" fullWidth><i className="back"></i>Nie</a>
                        </Grid>
                        <Grid item xs>
                            <Button className="button full-width" onClick={()=>removeUser()}>
                                {isLoading && 
                                    <span className="loader"></span>
                                }
                                {!isLoading && 
                                    <span>Tak</span>
                                }
                            </Button>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>
            <Dialog open={openGroupDelete} onClose={()=>setOpenGroupDelete(false)} style={{padding:"10px"}}>
                <DialogContent>
                    <DialogContentText>
                        Czy na pewno chcesz usunąć grupę?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Grid container spacing={2}>
                        <Grid item xs={4}>
                            <a onClick={()=>setOpenGroupDelete(false)} className="button button-cancel" fullWidth><i className="back"></i>Nie</a>
                        </Grid>
                        <Grid item xs>
                            <Button className="button full-width" onClick={()=>removeGroup()}>
                                {isLoading && 
                                    <span className="loader"></span>
                                }
                                {!isLoading && 
                                    <span>Tak</span>
                                }
                            </Button>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>
            <Dialog open={openGroupContactDelete} onClose={()=>setOpenGroupContactDelete(false)} style={{padding:"10px"}}>
                <DialogContent>
                    <DialogContentText>
                        Czy na pewno chcesz usunąć kontakt a grupy?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Grid container spacing={2}>
                        <Grid item xs={4}>
                            <a onClick={()=>setOpenGroupContactDelete(false)} className="button button-cancel" fullWidth><i className="back"></i>Nie</a>
                        </Grid>
                        <Grid item xs>
                            <Button className="button full-width" onClick={()=>removeGroupContact()}>
                                {isLoading && 
                                    <span className="loader"></span>
                                }
                                {!isLoading && 
                                    <span>Tak</span>
                                }
                            </Button>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>
            <ContactListComponent isOpenContactList={isOpenContactList} setOpenContactList={setOpenContactList} refreshItems={refreshItems} groupId={groupId} groupName={groupName}></ContactListComponent>
        </div>
    );
}

export default ContactComponent;