import React, { useState, useEffect } from 'react';
import { Button,Grid } from '@mui/material';
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';

const PayComponent = (props) => {
    const { register,  getValues, setValue } = useForm();
    const { t, i18n, ready } = useTranslation();
    
    useEffect(() => {
        //if(document.getElementById('payu-card-number').children.length>0)
        //{
            //document.getElementById('payu-card-number').children[0].style.width='300px';
            //document.getElementById('payu-card-date').children[0].style.width='200px';
            //document.getElementById('payu-card-cvv').children[0].style.width='200px';
        //}
    }, []);

    const handleCloseExit = async () => {
        props.setIsOpenPay(false);
    };

    return (

            <div>
                {props.isOpenPay && 
                    <div className="popup popup-2">
                        <Grid container>
                            <Grid item xs={12}>
                                <Grid container spacing={1}>
                                    <Grid item xs={11}>
                                        {props.isHaveToPaid<1 && <h5>Twoja subsrypcja wygasła</h5>}
                                        {props.isHaveToPaid>=1 && <h5>Twój okres próbny dobiega końca</h5>}
                                    </Grid>
                                    {props.isHaveToPaid>=1 && 
                                    <Grid item xs={1} className="right-element">
                                        <a className="x" onClick={()=>{props.setIsBlur(false);props.setIsOpenPay(false);props.setDisabledPayment(false);}}></a>
                                    </Grid>}
                                </Grid>
                            </Grid>
                            {props.isHaveToPaid<1 && 
                            <Grid item xs={12}>
                                <p>Nie udało się przedłużyć subskrypcji z powodu braku płatności. Wysyłka dokumentów do podpisu została zablokowana. Aby ponownie korzystać z usługi, uzupełnij dane płatności i opłać subskrypcję. Możesz to zrobić w zakładce "Płatności" w panelu użytkownika.</p>
                            </Grid>}
                            {props.isHaveToPaid>=1 && 
                            <Grid item xs={12}>
                                <p>Za {props.isHaveToPaid<1 ? '0 dni': (props.isHaveToPaid===1 ? '1 dzień': props.isHaveToPaid+' dni')} Twój okres próbny dobiegnie końca. Aby móc dalej korzystać z serwisu konieczne jest podpięcie karty kredytowej. Możesz zrezygnować w dowolnym momencie.</p>        
                            </Grid>}
                            <Grid item xs={12}>
                                <Button onClick={()=>{props.setIsOpenPayLoad(true);props.setIsOpenPay(false);props.setIsBlur(true);}} className="blue-font">Przejdż do płatności</Button>
                            </Grid>
                        </Grid>
                    </div>
                }
            </div>
    );
}

export default PayComponent;